export let productMovie = {
	init: function(){
		let options = {
			id: "",
			byline: false,
			portrait: false,
			title: false,
			autoplay: true,
			playsinline: true,
			controls: false,
			muted: true,
			loop: true,
			background: 1
		}
		productMovie.target = document.querySelector("#player");
		if(productMovie.target){
			options.id = productMovie.target.getAttribute("data-movie-id");
			let player = new Vimeo.Player("player", options);
			player.setVolume(0);
			player.setLoop(true).then( function(loop){
				//console.log("loop setting:", loop)
				player.play();
				player.on('ended', function(data) {
					//console.log("ended", count, data);
					/*
					player.setCurrentTime(0).then( function(){
						player.play();
						console.log("player try play")
					});
					*/
					/**/
				});
			});
		}
	}
}