import { UAParser } from 'ua-parser-js';
import { Framework } from './_framework.js';

let uaResult 	= UAParser();
let device 		= uaResult.device;
let browser 	= uaResult.browser.name.replace(/\s+/g, "").toLowerCase();
let $html 		= document.documentElement;
let isPage 		= "";
$html.classList.add(browser);
//console.log(uaResult);
$html.classList.add( uaResult.os.name.split(" ")[0].toLowerCase() );

var agent = window.navigator.userAgent.toLowerCase();
var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;


if(device.type === "mobile" || ipad){
	$html.classList.add("mobile");
	if(device.model === "iPhone"){
		$html.classList.add(device.model);
	}else{
		$html.classList.add("android");
	}
	if(ipad){
		$html.classList.add("ipad");
	}
}else{
	$html.classList.add("pc");
}
function checkNav(){
	let pathname = location.pathname;
	console.log(pathname);
	let globalNavLists = document.querySelectorAll("#s-glbnav ul li a");
	globalNavLists.forEach( ($nav) => {
		let href = $nav.getAttribute("href");
		if(href === pathname){
			$nav.classList.add("current");
		}
	});
	let footerNavLists = document.querySelectorAll("#s-footer ul li a");
	footerNavLists.forEach( ($nav) => {
		let href = $nav.getAttribute("href");
		if(href === pathname){
			$nav.classList.add("current");
		}
	});
}
document.addEventListener('DOMContentLoaded', function () {
	let fw = new Framework();
	checkNav();
	let $body = document.querySelector("body");
	if($body.classList.contains("disable")){
		$body.classList.remove("disable");
	}
});