import Cookies from 'js-cookie';
import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { drawer } from './lib/_drawer.js';
import { slideshow, imgSlideshow } from './lib/_slideshow.js';
import { getProducts } from './lib/_get_products.js';
import { productSlideshow } from './lib/_product_slideshow.js';
import { accordion } from './lib/_accordion.js';
import { productMovie } from './lib/_product_movie.js';
import { carousels } from './lib/_carousel.js';
import { interview } from './lib/_interview.js';
let $this;
export class Framework {
	constructor(){
		$this = this;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		//console.log(this.pageinfo);
		//this.debugNav();
	}
	
	checkURL(){
		$this.changeContent();
	}
	debugNav(){
		let $html 		= document.documentElement;
		document.addEventListener("keyup", (e) => {
			//console.log(e.keyCode);
			if(e.keyCode === 71){
				if( !$html.classList.contains("isdebug") ){
					$html.classList.add("isdebug");
				}else{
					$html.classList.remove("isdebug");
				}
			}
		}, false);
	}
	changeContent(){
		$this.afterChangePage();
	}
	
	afterChangePage(){
		if($this.pageinfo.path === "/shop" || $this.pageinfo.path === "/shop/" || $this.pageinfo.path === "/"){
			let $body = document.querySelector("body");
			if( $this.checkCookie()) {
				$body.classList.add("active_index");
				document.querySelector("#intro").remove();
				document.querySelector("#bg_cover").remove();
				setTimeout(function(){
					$body.classList.remove("hide");
					setTimeout(function(){
						document.documentElement.classList.add("red");
					}, 300);
				}, 600);
			}else{
				Cookies.set("gotonotsubaki_visited", "1");
				$body.classList.remove("hide");
				setTimeout(function(){
					$body.classList.add("active_index");
					setTimeout(function(){
						document.querySelector("#intro").remove();
						document.querySelector("#bg_cover").remove();
						setTimeout(function(){
							document.documentElement.classList.add("red");
						}, 300);
					}, 1500);
				}, 1000);
			}
		}
		common.init();
		slideshow.init();
		accordion.init();
		//console.log(this.pageinfo, this.pageinfo.pathArry[2]);
		
		carousels.init();
		if($this.pageinfo.pathArry.length >= 4){
			if($this.pageinfo.pathArry[2] === "products"){
				productSlideshow.init();
				//getProducts.init();
				productMovie.init();
			}
			if($this.pageinfo.pathArry.includes("sayuri-yoshinaga")){
				interview.init();
			}
			//console.log($this.pageinfo.pathArry[$this.pageinfo.pathArry.length - 1]);
			if( $this.pageinfo.pathArry[$this.pageinfo.pathArry.length - 1] === "story" ){
				imgSlideshow.init();
			}
		}
		if($this.pageinfo.path === "/shop" || $this.pageinfo.path === "/shop/" || $this.pageinfo.path === "/"){
			imgSlideshow.init();
			
		}
		common.resize();
		common.nav();
		drawer.init();
		this.makeGrid();
		this.checkLastConfirm();
	}
	makeGrid(){
		let HTML = `<div id="grid"><div id="g_inner">`;
		for(let i = 0; i < 12; i++){
			if(i < 6){
				HTML += `<div class="g"></div>`
			}else{
				HTML += `<div class="g pc"></div>`
			}
		}
		HTML += `</div></div>`;
		document.querySelector("body").insertAdjacentHTML("beforeend", HTML)
		//両サイド80固定
		//赤が%
		//間の11本 64px固定
	}
	checkLastConfirm(){
		let blocks = document.querySelectorAll(".p-checkout_confirm__inner__list__block");
		if(blocks.length > 0){
			blocks[blocks.length - 1].classList.add("last");
		}
	}
	checkCookie(){
		let hasCookie = Cookies.get("gotonotsubaki_visited");
		let hasVisited = false;
		if(hasCookie){
			hasVisited = true;
		}
		return hasVisited;
	}
}