import { load } from './_load.js';
export let drawer = {
	target: null,
	init: function(){
		drawer.target = document.querySelector(".s-drawer");
		//console.log("drawer init");
		if( drawer.target ){
			drawer.loadItems();
		}
	},
	loadItems: function(){
		load.getJson("https://d2w53g1q050m78.cloudfront.net/ecgotonotsubakic/uploads/json/products.json", {}).then( (res) => {
			drawer.setItem(res);
		});
	},
	setItem: function(res){
		let HTML = "";
		
		for(let i = 0; i < res.lineups.length; i++){
			let d = res.lineups[i];
			if(d.inDrawer){
				HTML += `<li>
					<div class="cols">
						<figure><img src="${d.image}" alt="${d.name}" width="106" height="106"></figure>
						<div class="col_r">
							<h3>${d.name}</h3>
							<p class="txt_price">&yen;${d.price}</p>
							<a class="c-btn bg_white round small h40" href="${d.link}">
								<div class="inr"><span>購入する</span></div>
							</a>
						</div>
					</div>
				</li>`;
			}
		}
		drawer.target.querySelector(".c-list-drawer ul").innerHTML = HTML;
		drawer.nav();
	},
	nav: function(){
		let $btnDrawer = document.querySelector("[data-toggle-drawer]");
		$btnDrawer.removeEventListener("click", drawer.toggleDrawer, false);
		$btnDrawer.addEventListener("click", drawer.toggleDrawer, false);
	},
	toggleDrawer: function(e) {
	//console.log("toggle drawer")
		let $html 		= document.documentElement;
		if( !$html.classList.contains("show-drawer") ){
			$html.classList.add("show-drawer");
		}else{
			$html.classList.remove("show-drawer");
		}
	}
}