class Movie {
	constructor(movieID, playerID){
		this.id 		= movieID;
		this.player 	= null;
		this.isPlaying 	= false;
		this.setPlayer(playerID);
	}
	setPlayer(playerID){
		let t = this;
		let options = {
			id: t.id,
			byline: false,
			portrait: false,
			title: false,
			autoplay: false,
			plausinline: true,
			controls: false,
			muted: false
		}
		//console.log(playerID, options);
		this.player = new Vimeo.Player(playerID, options);
		this.player.setVolume(0);
	}
	pause(){
		
	}
}
export let movies = {
	players: [],
	makePlayer: function($dom){
		movies.players = [];
		let lists = $dom.querySelectorAll(".list_slideshow li");
		lists.forEach( ($list, index) => {
			let movieID = $list.querySelector(".player").getAttribute("data-movie-id");
			movies.players[index] = new Movie( movieID, $list.querySelector(".player").getAttribute("id") );
			//console.log(movies.players)
		});
		movies.nav();
		return movies.players;
	},
	nav: function(){
		let t = this;
		let $btnSounds = document.querySelectorAll("button[data-sound]");
		$btnSounds.forEach( ($btnSound) => {
			if('ontouchstart' in window ){
				$btnSound.addEventListener("touchend", { me: t, handleEvent: t.toggleSound}, false);
			}else{
				$btnSound.addEventListener("click", { me: t, handleEvent: t.toggleSound}, false);
			}
		});
	},
	toggleSound: function(e){
		let t = this;
		let index 		= parseInt( e.currentTarget.getAttribute("data-sound") );
		let lists 		= e.currentTarget.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".list_slideshow li");
		let currentList = lists[index];
		let thisPlayer 	= movies.players[index];
		thisPlayer.player.getVolume().then(function(volume) {
			//console.log("current:", volume);
		 	if( volume === 0 ){
				thisPlayer.player.setMuted(false);
				thisPlayer.player.setVolume(.5).then( (vol) => {
					currentList.classList.remove("off");
					currentList.classList.add("on");
				});
			 }else{
				thisPlayer.player.setVolume(0).then( (vol) => {
					thisPlayer.player.setMuted(true);
					currentList.classList.remove("on");
					currentList.classList.add("off");
				});
			 }
		});
	}
}