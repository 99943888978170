export let accordion = {
	init: function(){
		let $accordions = document.querySelectorAll("[data-accordion]");
		//console.log($accordions);
		if($accordions.length > 0){
			$accordions.forEach( ($accordion) => {
				let evt = "click";
				if('ontouchstart' in window){
					evt = "touchend";
				}
				let $btn = $accordion.querySelector("[data-toggle-accordion]");
				//console.log(evt, $btn);
				$btn.removeEventListener(evt, accordion.toggleBlock, false);
				$btn.addEventListener(evt, accordion.toggleBlock, false);
			});
		}
	},
	toggleBlock: function(e){
		let $this = this;
		let $parent = $this.parentNode.parentNode;
		let $content = $parent.querySelector(".accordion_content");
		let $contentInner = $content.querySelector(".content_inner");
		let _h = $contentInner.clientHeight;
		if( !$parent.classList.contains("open") ){
			$parent.classList.add("open")
			$content.style.height = _h + "px";
		}else{
			$parent.classList.remove("open");
			$content.style.height = "0px";
		}
		
		//console.log($parent);
	}
}