class Movie {
	constructor(movieID, playerID){
		this.id 		= movieID;
		this.player 	= null;
		this.isPlaying 	= false;
		this.setPlayer(playerID);
	}
	setPlayer(playerID){
		let t = this;
		let options = {
			id: t.id,
			byline: false,
			portrait: false,
			title: false,
			autoplay: false,
			plausinline: true,
			controls: true,
			muted: false,
			loop: true
		}
		//console.log(playerID, options);
		
		t.player = new Vimeo.Player(playerID, options);
		t.player.setVolume(0);
		
	}
	play(){
		let $this = this;
		$this.player.setCurrentTime(0);
		$this.player.play().then( () => {
			$this.isPlaying = true;
		});
	}
	playWithSound(){
		let $this = this;
		$this.player.setCurrentTime(0);
		$this.player.setVolume(.5);
		//$this.player.setCurrentTime(0);
		$this.player.play().then( () => {
			$this.isPlaying = true;
		});
	}
	pause(){
		let $this = this;
		this.player.off("ended");
		if($this.isPlaying){
			$this.player.pause().then( function(){
				$this.player.play();
				let $playerWrap = document.querySelector("#player");
				if( $playerWrap.classList.contains("playing") ){
					$playerWrap.classList.remove("playing");
				}
			});
		}
	}
}
export let interview = {
	player: "",
	init: function(){
		//console.log("here");
		let movieID = document.querySelector(".player").getAttribute("data-movie-id");
		//console.log(movieID, document.querySelector(".player").getAttribute("id"));
		interview.player = new Movie( movieID, document.querySelector(".player").getAttribute("id") );
		console.log(interview.player)
		setTimeout(function(){
			interview.player.player.play();
		}, 1000);
		interview.nav();
	},
	nav: function(){
		let $play = document.querySelector(`[data-play-movie]`);
		$play.addEventListener("click", interview.play, false);
	},
	play: function(){
		let $playerWrap = document.querySelector("#player");
		if( !$playerWrap.classList.contains("playing") ){
			$playerWrap.classList.add("playing");
		}
		interview.player.playWithSound();
	},
	pause: function(){
		let $playerWrap = document.querySelector("#player");
		if( $playerWrap.classList.contains("playing") ){
			$playerWrap.classList.remove("playing");
		}
		interview.player.player.pause();
	}
}