import { object } from './_obj.js';
let carousel = {
	current: 0,
	max: 0,
	maxCol: 4,
	beforeLayout: 0,
	layout: 0,
	target: null,
	$prev: null,
	$next: null,
	nav: function(){
		//console.log(this.target);
		let c = this;
		c.$prev = this.target.querySelector("button.btn_prev");
		c.$next = this.target.querySelector("button.btn_next");
		
		if(c.$prev){
			c.$prev.removeEventListener("click", { obj: c, handleEvent: c.dec}, false);
			c.$prev.addEventListener("click", { obj: c, handleEvent: c.dec}, false);
			
			c.$next.removeEventListener("click", { obj: c, handleEvent: c.inc}, false);
			c.$next.addEventListener("click", { obj: c, handleEvent: c.inc}, false);
		}
	},
	inc: function(e){
		//console.log(this, e);
		let c = this.obj;
		c.current++;
		if( c.current >= c.max + 1 ){
			c.current = c.max;
		}else{
			c.change();
		}
	},
	dec: function(e){
		let c = this.obj;
		c.current--;
		if(c.current < 0){
			c.current = 0;
		}else{
			c.change();
		}
	},
	change: function(){
		let c = this;
		//console.log( c.current, c.max );
		if(c.max < 0 || c.current < 0){ return false}
		let target = c.target.querySelector("ul");
		let w = target.querySelector("li").clientWidth;
		let posX = (w + 64)*c.current*-1;
		//console.log(w, c.current);
		if( window.matchMedia('all and (min-width : 1024px)').matches ){
			target.style.transform = `translate3d(${posX}px, 0, 0)`;
		}else{
			target.style.transform = ``;
		}
		
		c.checkNav();
	},
	check: function(index){
		let c = this;
		let len = c.target.querySelectorAll("li").length;
		if( window.matchMedia('all and (min-width : 1280px)').matches ){
			c.layout 	= 3;
			/*
			if( c.maxCol === 4 ){
				c.max 		= len - c.maxCol + 1;
			}else{
				c.max 		= len - c.maxCol;
			}
			*/
			c.max 		= len - c.maxCol;
			
			if(c.current >= c.max){
				c.current = c.max;
			}
			if(c.max === 0){
				if(c.current > 0){
					c.current = 0;
				}
			}
			/*
			if( c.beforeLayout === 2){
				if( c.current > 0 ){
					c.current = c.current - 1;
				}
			}
			*/
		}else if( window.matchMedia('all and (min-width : 1024px)').matches ){
			c.layout = 2;
			c.max = len - 3;
			if(c.current >= c.max){
				c.current = c.max;
			}
			/*
			if( c.beforeLayout === 3){
				if( c.current > 0 ){
					c.current = c.current + 1;
				}
			}*/
		}else{
			c.layout = 0;
			c.max = 0;
		}
		if( c.max >= 1 ){
			if( c.target.classList.contains("noscroll") ){
				c.target.classList.remove("noscroll");
			}
		}else{
			if( !c.target.classList.contains("noscroll") ){
				c.target.classList.add("noscroll");
			}
		}
		c.change();
		c.beforeLayout = c.layout;
	},
	checkNav: function(){
		let c = this;
		if(c.$next){
			if(c.current === c.max){
				if( !c.$next.classList.contains("disable") ){
					c.$next.classList.add("disable")
				}
				if( c.$prev.classList.contains("disable") ){
					c.$prev.classList.remove("disable")
				}
			}else if(c.current === 0){
				if( !c.$prev.classList.contains("disable") ){
					c.$prev.classList.add("disable")
				}
				if( c.$next.classList.contains("disable") ){
					c.$next.classList.remove("disable")
				}
			}else{
				if( c.$next.classList.contains("disable") ){
					c.$next.classList.remove("disable")
				}
				if( c.$prev.classList.contains("disable") ){
					c.$prev.classList.remove("disable")
				}
			}
		}
		if( window.matchMedia('all and (min-width : 1280px)').matches ){
			if( c.target.querySelectorAll("li").length === c.maxCol){
				if( !c.$next.classList.contains("disable") ){
					c.$next.classList.add("disable")
				}
			}
		}
	}
}

export let carousels = {
	blocks: [],
	init: function(){
		function start(){
			carousels.blocks = [];
			let blocks = document.querySelectorAll(".c-carousel");
			let navHTML = 	`
								<button class="btn_prev disable">
									<div class="icon">
										<svg x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve"><path class="st0" d="M11,1c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4L6.4,8l5.3,5.3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0l-6-6c-0.4-0.4-0.4-1,0-1.4l6-6C10.5,1.1,10.7,1,11,1z"/></svg>
									</div>
								</button>
								<button class="btn_next">
									<div class="icon">
										<svg x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve"><path class="st0" d="M5,15c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4L9.6,8L4.3,2.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6c0.4,0.4,0.4,1,0,1.4l-6,6C5.5,14.9,5.3,15,5,15z"/></svg>
									</div>
								</button>
							`;
			blocks.forEach( ($block, index) => {
				let _c = object(carousel);
				_c.target = $block;
				_c.current = 0;
				let len = $block.querySelectorAll("li").length;
				_c.maxCol = parseInt( $block.getAttribute("data-max") );
				if( window.matchMedia('all and (min-width : 1280px)').matches ){
					_c.max 	= len - _c.maxCol;
				}else if( window.matchMedia('all and (min-width : 1024px)').matches ){
					_c.max = len - 3;
				}else{
				}
				
				//console.log(_c.target, _c.max);
				carousels.blocks.push(_c);
				if($block.querySelectorAll("button.btn_prev").length > 0){
					
				}else{
					$block.insertAdjacentHTML('beforeend', navHTML);
				}
			});
			//console.log(carousels.blocks);
			carousels.initBlock();
		}
		let isProductDetail = document.querySelector(".s-lineup .c-carousel");
		//console.log(isProductDetail)
		if(isProductDetail){
			check();
			function check(){
				let listLen = isProductDetail.querySelectorAll("li").length;
				if(listLen > 0){
					start();
				}else{
					setTimeout(function(){
						check();
					}, 33);
				}
			}
		}else{
			start()
		}
	},
	initBlock: function(){
		window.addEventListener("resize", this.check, false);
		carousels.blocks.forEach( (c, index) => {
			c.nav();
			c.check(index);
		});
	},
	check: function(){
		carousels.blocks.forEach( (c, index) => {
			c.check(index);
		});
	}
}