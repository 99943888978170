import { shareSNS } from './lib/_share.js';
import { Scroll } from './lib/_scroll.js';
let $html 		= document.documentElement;
export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		//alert(window.innerWidth)
		common.resize();
		common.nav();
		common.scrl = new Scroll();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		let $menu = document.querySelector("[data-menu]");
		let $bgNav = document.querySelector("#bg_nav");
		
		
		/*if ('ontouchstart' in window) {
			$menu.removeEventListener("touchend", common.toggleNav, false);
			$menu.addEventListener("touchend", common.toggleNav, false);
			
			$bgNav.removeEventListener("touchend", common.toggleNav, false);
			$bgNav.addEventListener("touchend", common.toggleNav, false);
		}else{
			
		}*/
		$menu.removeEventListener("click", common.toggleNav, false);
		$menu.addEventListener("click", common.toggleNav, false);
		
		$bgNav.removeEventListener("click", common.toggleNav, false);
		$bgNav.addEventListener("click", common.toggleNav, false);
		//select
		let $select = document.querySelector(".c_select select");
		if($select){
			$select.addEventListener("change", common.getSelectValue, false);
		}
		
		//page scroll
		let $btnIndexes = document.querySelectorAll(".list_index li a");
		if($btnIndexes.length > 0){
			$btnIndexes.forEach( ($btn) => {
				$btn.removeEventListener("click", common.scrollToSection, false);
				$btn.addEventListener("click", common.scrollToSection, false);
			});
		}
		let $btnTtlIndexes = document.querySelectorAll(".list_index h3 a");
		if($btnTtlIndexes.length > 0){
			$btnTtlIndexes.forEach( ($btn) => {
				$btn.removeEventListener("click", common.scrollToSection, false);
				$btn.addEventListener("click", common.scrollToSection, false);
			});
		}
	},
	toggleNav: ()=> {
		if( !$html.classList.contains("show-menu") ){
			$html.classList.add("show-menu");
		}else{
			$html.classList.remove("show-menu");
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		// カスタムプロパティの値を更新する
		document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
		
		let $wrapper = document.querySelector("#wrapper");
		let el = getComputedStyle($wrapper);
		let pt = parseInt( el.paddingTop.replace("px", "") );
		let $footer	 = document.querySelector("#s-footer");
		let totalH 	= $wrapper.offsetHeight + pt;
		let footerH = $footer.clientHeight;
		
		//$wrapper.style.marginBottom = footerH + "px";
		//console.log(footerH, winH);
		if(footerH < winH){
			if( $body.classList.contains("f_w") ){
				$body.classList.remove("f_w");
			}
			if( !$body.classList.contains("w_f") ){
				$body.classList.add("w_f");
			}
		}else if(footerH >= winH){
			if( $body.classList.contains("w_f") ){
				$body.classList.remove("w_f");
			}
			if( !$body.classList.contains("f_w") ){
				$body.classList.add("f_w");
			}
			
			let wrapH = totalH;
			//console.log(wrapH);
			//$footer.style.marginTop = -totalH + "px";
			
			//$wrapper.style.paddingBottom = footerH + "px";
		}
		if( $body.getAttribute("data-page") === "photogallery"){
			
		}else{
			document.querySelector("[data-page-content]").style.paddingBottom = footerH + "px";
		}
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		common.beforeW = winW;
	},
	getSelectValue: function(){
		let val = this.value;
		location.href = val;
		//console.log(val);
	},
	scrollToSection: function(e){
		e.preventDefault();
		let thisID = this.getAttribute("data-name");
		let logo = document.querySelector("h1#logo");
		let posY = document.querySelector(`#${thisID}`).getBoundingClientRect().top + window.pageYOffset - logo.clientHeight - logo.getBoundingClientRect().top*2;
		if(thisID === "index3" || thisID === "index4"){
			posY = document.querySelector(`#${thisID}`).getBoundingClientRect().top + window.pageYOffset;
		}
		
		window.scroll({
			top: posY,
			behavior: "smooth"
		});
	}
}
