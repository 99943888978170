import { object } from './_obj.js';
let Block = {
	top: 0,
	show: 0
}

/*
座標
elem.getBoundingClientRect()
*/
let $this;
export class Scroll {
	constructor(){
		$this = this;
		this.memoTop = 0;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		
		this.checkBlock();
		this.checkScroll();
		$this.isProducts = false;
		let page = document.querySelector("body").getAttribute("data-page");
		if(page === "product_detail"){
			$this.isProducts = true;
		}
		
		let $html 		= document.documentElement;
		let scrollTimer = null;
		let scrollCallback = ()=>{
			/*
			if( $html.classList.contains("scrolling") ){
				$html.classList.remove("scrolling");
			}
			*/
			$this.memoTop = $this.topD;
			scrollTimer = null;
		}
		if(document.documentElement.classList.contains("mobile")){
			setInterval(function(){
				$this.memoTop = $this.topD;
			}, 100);
		}
		document.addEventListener("scroll", ()=>{
			$this.topD = window.pageYOffset;
			if($this.topD >= $this.memoTop){
				if( !$html.classList.contains("scrolling") ){
					$html.classList.add("scrolling");
				}
			}else{
				if( $html.classList.contains("scrolling") ){
					$html.classList.remove("scrolling");
				}
			}
			if($this.topD <= 10){
				if( $html.classList.contains("scrolling") ){
					$html.classList.remove("scrolling");
				}
			}
			$this.checkScroll();
			if( scrollTimer ) {
				clearTimeout(scrollTimer);
			}
			scrollTimer = setTimeout(scrollCallback, 200);
		}, false);
	}
	
	checkScroll(){
		$this.btmD = $this.topD + window.innerHeight;
		
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
		if($this.isProducts){
			let movieContent = document.querySelector(".s-movie_product .content");
			if(movieContent){
				let endMovie = document.querySelector(".s-movie_product .content").getBoundingClientRect().top + window.pageYOffset;
				let $target = document.querySelector(".s-movie_product");
				if( $this.btmD >= endMovie ){
					if(!$target.classList.contains("show_text")){
						$target.classList.add("show_text");
					}
				}else{
					if($target.classList.contains("show_text")){
						$target.classList.remove("show_text");
					}
				}
			}
		}
		let $body = document.querySelector("body");
		if($body.classList.contains("f_w")){
			let movePos = document.querySelector("#wrapper").offsetHeight;
			if($this.topD >= movePos){
				if( !$body.classList.contains("move_footer") ){
					$body.classList.add("move_footer");
				}
			}else{
				if($body.classList.contains("move_footer")){
					$body.classList.remove("move_footer");
				}
			}
		}
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll(".a");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}