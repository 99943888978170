export let productSlideshow = {
	current: 0,
	max: 0,
	target: null,
	isSP: false,
	init: function(){
		let p = productSlideshow;
		//-console.log("product slideshow");
		p.target = document.querySelector(".c-product_slideshow");
		if(p.target){
			p.current = 0;
			p.max = p.target.querySelectorAll(".slideshow_inner ul li").length;
			p.nav();
			p.change();
			if (window.matchMedia('(min-width: 1024px)').matches) {
				
			}else{
				p.setObserver();
			}
		}
	},
	nav: function(){
		let p = productSlideshow;
		let $navs = p.target.querySelectorAll(".nav_slideshow ul li");
		$navs.forEach( ($nav, index) => {
			$nav.removeEventListener("click", p.getIndex, false);
			$nav.addEventListener("click", p.getIndex, false);
		});
	},
	getIndex: function(e){
		productSlideshow.current = parseInt( this.getAttribute("data-index") );
		productSlideshow.change();
	},
	change: function(){
		let p = productSlideshow;
		let $lists 	= p.target.querySelectorAll(".slideshow_inner ul li");
		let $navs 	= p.target.querySelectorAll(".nav_slideshow ul li");
		$lists.forEach( ($list, index) => {
			if( index === p.current){
				$list.classList.add("active");
				$navs[index].classList.add("active");
			}else{
				if( $list.classList.contains("active") ){
					$list.classList.remove("active");
					$navs[index].classList.remove("active");
				}
			}
		});
	},
	setObserver: function(){
		let p = productSlideshow;
		const lists = p.target.querySelectorAll(".slideshow_inner ul li");
		let $navs 	= p.target.querySelectorAll(".nav_slideshow ul li");
		const options = {
			root: p.target,
			rootMargin: "0px -50%",
			threshould: 0
		}
		//console.log("observer")
		const observer = new IntersectionObserver(doWhenIntersect, options);
		// それぞれのboxを監視する
		lists.forEach(list => {
			observer.observe(list);
		});
		function doWhenIntersect(entries) {
			if (window.matchMedia('(max-width: 1023px)').matches) {
				$navs.forEach( ($nav) => {
					$nav.classList.remove("active");
				});
				entries.forEach( (entry, index) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("active");
						console.log(entry.target.getAttribute("data-index"))
						$navs[ parseInt( entry.target.getAttribute("data-index") ) ].classList.add("active");
					}else{
						if(entry.target.classList.contains("active")){
							entry.target.classList.remove("active");
						}
					}
				});
			}else{
				p.change();
			}
		}
	}
}