import { movies } from './_movies.js';
/*
TODO
- VIDEOではない時のスライドショーを作成するかの判断
- スライドショーが進んだ時にタイトルも変更する
*/
class Slide {
	constructor($slide){
		let t = this;
		this.target = $slide;
		this.count 	= 1;
		this.max 	= $slide.querySelectorAll("ul.list_slideshow li").length;
		this.type 	= $slide.getAttribute("data-type");
		this.flickStartX = 0;
		this.flickMoveX = 0;
		this.currentMoveX = 0;
		if(this.type === "video"){ //スライドショー内の複数プレイヤー格納
			t.players = movies.makePlayer(t.target);
		}
		//console.log(t.max);
		t.moveSlide();
		if(t.max > 1){
			this.makeNav();
			this.nav();
		}
		
		this.checkMoviePlayer();
		setTimeout(function(){
			t.target.classList.remove("notransition");
		}, 50);
	}
	makeNav(){
		let HTML = "";
		for(let i = 0; i < this.max - 2; i++){
			if(i === 0){
				HTML += `<li class="active"><button>${i}</button></li>`;
			}else{
				HTML += `<li><button>${i}</button></li>`;
			}
		}
		this.target.querySelector(".nav_slideshow ul").innerHTML = HTML;
	}
	nav(){
		let t = this;
		if('ontouchstart' in window ){
			t.target.addEventListener("touchstart", { me: t, handleEvent: t.touchStart}, false);
			t.target.addEventListener("touchmove", { me: t, handleEvent: t.touchMove}, false);
			t.target.addEventListener("touchend", { me: t, handleEvent: t.touchEnd}, false);
		}else{
		}
		/*
		document.addEventListener("keyup", function(e){
			console.log(e.keyCode)
			if(e.keyCode === 65){
				t.inc();
				t.moveSlide();
			}
		});
		*/
		t.target.addEventListener("transitionend", { me: t, handleEvent: t.afterTransition}, false);
		
		/*if(t.type === "video"){
			let sounds = document.querySelectorAll(`button[data-sound]`);
			sounds.forEach( ($sound, index) => {
				$sound.removeEventListener("click", { me: t, btn: $sound, handleEvent: t.toggleSound}, false);
				$sound.addEventListener("click", { me: t, btn: $sound, handleEvent: t.toggleSound}, false);
			});
		}*/
	}
	touchStart(e){
		let t = this;
		t.me.flickStartX = 0;
		t.me.flickStartX = e.touches[0].pageX;
		if( !t.me.target.classList.contains("touching") ){
			t.me.target.classList.add("touching");
		}
	}
	touchMove(e){
		let t = this;
		let current 	= e.touches[0].pageX;
		let diff 		= current - t.me.flickStartX;
		let posX 		= (diff/t.me.target.clientWidth)*100 + t.me.count*-100;
		//console.log(posX);
		t.me.target.querySelector("ul.list_slideshow").style.transform = `translate3d(${posX}%, 0, 0)`;
		t.me.flickMoveX = diff;
		//console.log(t.me.flickMoveX);
	}
	touchEnd(){
		let t = this;
		//console.log("touchEnd:", t.me.flickMoveX);
		if(t.me.flickMoveX < -80){
			t.me.inc();
			t.me.moveSlide();
		}else if(t.me.flickMoveX > 80){
			t.me.dec();
			t.me.moveSlide();
		}else{
			t.me.target.querySelector("ul.list_slideshow").style.transform = `translate3d(${t.me.count*-100}%, 0, 0)`;
		}
		if( t.me.target.classList.contains("touching") ){
			t.me.target.classList.remove("touching");
		}
		t.me.flickStartX 	= 0;
		t.me.flickMoveX 	= 0;
	}
	inc(){
		let t = this;
		t.count++;
	}
	dec(){
		let t = this;
		t.count--;
	}
	moveSlide(){
		let t = this;
		t.target.querySelector("ul.list_slideshow").style.transform = `translate3d(${t.count*-100}%, 0, 0)`;
		
		//t.target.removeEventListener("transitionend", { me: t, handleEvent: t.afterTransition}, false);
		
	}
	afterTransition(e){
		let t = this;
		let isEnd = false;
		if(t.me.count === 0){
			isEnd = true;
			t.me.target.classList.add("notransition");
			t.me.count = t.me.max - 2;
			t.me.moveSlide();
		}else if(t.me.count >= t.me.max -1){
			isEnd = true;
			t.me.target.classList.add("notransition");
			t.me.count = 1;
			t.me.moveSlide();
		}
		if(isEnd = true){
			setTimeout(function(){
				t.me.target.classList.remove("notransition");
				t.me.checkMoviePlayer();
			}, 100);
		}else{
			t.checkMoviePlayer();
		}
		//console.log(t.me, e.currentTarget);
	}
	checkMoviePlayer(){
		let t = this;
		for(let i = 0; i < t.max; i++){
			let thisPlayer = t.players[i];
			if(i === t.count){
				//thisPlayer.player.setCurrentTime(0);
				/*
				thisPlayer.player.on("timeupdate", ()=> {
					thisPlayer.player.getCurrentTime().then( (data)=>{
						console.log(data)
					} )
				});
				*/
				thisPlayer.player.play().then( () => {
					thisPlayer.isPlaying = true;
					thisPlayer.player.off("ended");
					thisPlayer.player.on("ended", () => {
						//console.log("end")
						//console.log(t.max);
						if(t.max > 2){
							t.inc();
							t.moveSlide();
						}else{
							thisPlayer.player.setCurrentTime(0).then( () => {
								thisPlayer.player.play();
							})
						}
					});
				});
			}else{
				thisPlayer.player.off("ended");
				if(thisPlayer.isPlaying){
					thisPlayer.player.pause().then( function(){
						thisPlayer.player.off("ended");
					});
				}
			}
		}
		t.activeList();
	}
	activeList(){
		let t = this;
		let navs 		= t.target.querySelectorAll(".nav_slideshow ul li");
		let $captions 	= document.querySelectorAll(".caption_slideshow ul li");
		//console.log($captions[t.count-1], t.count)
		navs.forEach( ($nav, index) => {
			if( index === t.count - 1 ){
				$nav.classList.add("active");
				if($captions[index]){
					$captions[index].classList.add("active");
				}
			}else{
				if($nav.classList.contains("active")){
					$nav.classList.remove("active");
				}
				if($captions[index]){
					$captions[index].classList.remove("active");
				}
			}
		});
	}
}

export let slideshow = {
	collection: [],
	init: () => {
		slideshow.doms = document.querySelectorAll(".c-slideshow");
		slideshow.doms.forEach( ($slide, index) => {
			slideshow.collection[index] = new Slide($slide);
		});
		//console.log(slideshow.collection);
	}
}

class ImgSlideshow{
	constructor($target){
		this.target = $target;
		this.current = 0;
		this.max = $target.querySelectorAll(".slide li").length;
		
		this.change();
	}
	inc(){
		this.current++;
		if(this.current >= this.max){
			this.current = 0;
		}
		this.change();
	}
	change(){
		let t = this;
		//console.log(t.target)
		let lists = t.target.querySelectorAll(".slide li");
		let navLists = t.target.querySelectorAll(".nav_current li");
		//console.log(lists);
		lists.forEach( ($list, index) => {
			if( t.current === index ){
				$list.classList.add("active");
				if(navLists[index]){
					navLists[index].classList.add("active");
				}
			}else{
				//console.log(navLists[index], index)
				if($list.classList.contains("active")){
					$list.classList.remove("active");
					if(navLists[index]){
						navLists[index].classList.remove("active");
					}
				}
			}
		});
	}
	
}

export let imgSlideshow = {
	startTime: 0,
	endTime: 0,
	duration: 5000,
	timer: null,
	slides: [],
	init: function(){
		let $slides = document.querySelectorAll("[data-slideshow]");
		$slides.forEach( ($slide, index) => {
			let pager = "";
			for(let i = 0; i < $slide.querySelectorAll("ul.slide li").length; i++){
				pager += `<li><button>${i}</button></li>`
			}
			let $navUl = $slide.querySelector(".nav_current ul");
			if( $navUl ){
				$slide.querySelector(".nav_current ul").innerHTML = pager;
			}
			
			imgSlideshow.slides[index] = new ImgSlideshow($slide);
		});
		imgSlideshow.resetTime();
		
		imgSlideshow.timer = setInterval( ()=>{
			imgSlideshow.checkNow();
		}, 100);
	},
	getNow: function(){
		let now = new Date();
		return now.getTime();
	},
	resetTime: function(){
		let is = imgSlideshow;
		is.startTime = is.getNow();
		is.endTime = is.startTime + is.duration;
	},
	checkNow: function(){
		let is = imgSlideshow;
		let current = is.getNow();
		if( current - is.startTime >= is.duration){
			imgSlideshow.slides.forEach( (slide) =>{
				//console.log(slide);
				slide.inc();
			});
			is.resetTime();
		}
	}
}